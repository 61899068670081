@font-face {
  font-family: 'CarbonBlock';
  src: url('/public/fonts/CarbonBlock.ttf') format('truetype');
}

body{
  font-family: 'CarbonBlock';
}

.view {
  padding: 0 5vw;
  min-height: 100vh;
}

/* Customize themes */
.view--yellow {
  background-image: url('/public/background/AMARILLO DE FONDO.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--yellow .header-button {
  border: 2px solid black;
}


.view--black {
  background-image: url('/public/background/NEGRO DE FONDO.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--black .header-title{
  color: whitesmoke; 
}

.view--black .item-category {
  color: #ffcc04;
}

.view--black .header-button {
  border: 2px solid whitesmoke;
  color: whitesmoke;
}


.view--white{
  background-image: url('/public/background/BLANCO DE FONDO.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--white .title, .view--white .item-category{
  /* color: #f01c24; */
  color: #1e2177;
}

.view--white .header-button {
  border: 2px solid #1e2177;
  color: #1e2177;
}


.view--red {
  background-image: url('/public/background/FONDO ROJO.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--red .header-title{
  color: whitesmoke;
}

.view--red .item-category {
  color: #ffcc04;
}

.view--red .header-button {
  border: 2px solid whitesmoke;
  color: whitesmoke;
}


.view--blue {
  background-image: url('/public/background/FONDO AZUL MARINO.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--blue .header-title{
  color: #ffcc04;
}

.view--blue .item-category {
  color: whitesmoke;
}

.view--blue .header-button {
  border: 2px solid #ffcc04;
  color: whitesmoke;
}


.view--light-blue {
  background-image: url('/public/background/FONDO AZUL CLARO.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--light-blue .header-title{
  color: #ffcc04;
}

.view--light-blue .item-category {
  color: whitesmoke;
}

.view--light-blue .header-button {
  border: 2px solid #ffcc04;
  color: whitesmoke;
}


.view--light-blue2 {
  background-image: url('/public/background/FONDO AZUL CLARO 2.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.view--light-blue2 .header-title{
  color: #ffcc04;
}

.view--light-blue2 .item-category {
  color: whitesmoke;
}

.view--light-blue2 .header-button {
  border: 2px solid #ffcc04;
  color: whitesmoke;
}

/* General styles */

.view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  padding-top: 3rem;
}

.header-title {
  text-transform: uppercase;
  font-size: 4vw;
  letter-spacing: .2vw;
  font-family: 'CarbonBlock';
}

.header-logo {
  display: flex;
  justify-content: flex-end;
}

/* .header-logo img {
  margin: 0 1vw;
  height: 10vw;
} */

.header-button {
  cursor: pointer;
  background: none;
  padding: .5rem 1rem;  
  font-size: 1rem;
}

.header-button i {
  margin-right: .5rem;
}

.subcategory-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2vw;

  padding: 2rem;
}

.item-image {
  width: 100%;
  padding: 0;
  margin: 0;
}

.item-image img {
  width: 100%;
}

.nav-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  transition: 0.3s ease-in-out;
  scale: 1;
}

.nav-option:hover {
  transition: 0.3s ease-in-out;
  scale: 1.1;
  cursor: pointer;
}

.item-category {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 1rem 0;
  font-family: 'CarbonBlock';
}

/* Responsive */

@media (max-width: 768px) {
  .header-title {
    font-size: 10vw;
  }
  .header-logo{
    justify-content: center;
  }
  .header-logo img {
    width: 5rem;
    height: 5rem;
  }
  .subcategory-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .nav-button-image {
    height: 5rem;
  }
  .item-category {
    font-size: 1rem;
  }
}

#logo {
  object-fit: contain;
}